@import '../../variables.scss';

.button {
  padding: 10px 20px;
  border: 0;
  border-radius: 5px;
  color: #fff;
  background-color: $mmsDokkBlar;
  font-size: 1rem;
  transition: all 0.3s ease-in;
}

.button:hover {
  background-color: $mmsLjosBlar;
  cursor: pointer;
}

.button__inactive {
  background-color: rgb(165, 174, 177) !important;
}

.button__inactive:hover {
  background-color: rgb(165, 174, 177) !important;
  cursor: initial !important;
}

.button__danger {
  background-color: rgb(238, 101, 101) !important;
}

.button__danger:hover {
  background-color: rgb(228, 3, 3) !important;
  cursor: initial !important;
}
