.passwordInput {
  margin-top: 1em;

  div {
    position: relative;
    display: flex;
    flex-direction: column;

    img {
      padding-right: 0.2em;
      position: absolute;
      right: 0;
      width: 2em;
    }
  }
}
