.numStudents {
  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }

  p {
    text-align: center;
  }

  form {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  form input {
    margin-right: 1rem;
  }

  form button {
    margin-left: 1rem;
  }
}
