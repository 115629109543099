.resultsNemenda {
  > div {
    display: flex;
  }

  .textaDiv {
    width: 100%;

    p {
      width: 100%;
      padding-left: 1em;
      border-radius: 5px;
    }
  }

  .nemendaListi {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-bottom: 5em;
  }

  .downloadDiv {
    display: flex;
    justify-content: center;
    margin: 2em 0;

    img {
      margin-right: 1em;
    }

    .pdfIcon {
      width: 3em;
      cursor: pointer;
    }
  }
}
