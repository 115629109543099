.changePassword {
  margin-top: 3em;

  h2 {
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;

    label {
      margin-top: 1em;
    }

    button {
      margin: 2em auto;
      width: 50%;
    }
  }
}
