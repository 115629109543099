@import '../../variables.scss';

.skraForm {
  height: 100%;
  flex: 2;
  display: flex;

  > div {
    display: flex;
    align-items: flex-start;
  }

  .myndaDiv {
    margin-right: 1em;
  }
  > div > p {
    border-radius: 5px;
  }

  form {
    width: 100%;

    label {
      display: flex;
      flex-direction: column;
      margin-top: 1em;
    }

    label:first-of-type {
      margin-top: 0;
    }
  }

  .kennitoluTextaDiv,
  .dagsetningarTextaDiv {
    display: flex;
  }
  .kennitoluTextaDiv p:first-child,
  .dagsetningarTextaDiv p:first-child {
    margin-right: 1em;
  }

  .datePickerDiv {
    display: flex;

    img {
      margin-bottom: 1em;
    }
  }

  .kynDiv {
    > div {
      display: flex;
      justify-content: space-evenly;
      background-color: rgb(228, 226, 226);
      width: 50%;
      border-radius: 5px;
      padding: 0.5em 0;
      margin-bottom: 1em;
    }

    label {
      margin-top: 0;
    }
  }

  .dropDown {
    margin-bottom: 0.5em;

    label {
      display: block;
    }

    > div {
      display: flex;
    }

    select {
      width: 50%;
    }
  }

  .reviewDiv {
    textarea {
      width: 100%;
      height: 10em;
      background-color: $input-color;
      border-radius: 5px;
      font-family: inherit;
      padding: 0.5em;
    }
  }

  .submitDiv {
    display: flex;
    margin-top: 2em;
    justify-content: space-between;

    button {
      width: 40%;
    }
  }

  img {
    display: flex;
    align-items: center;
    margin-left: 1em;
  }

  .nidurstodur p,
  > div > div > p {
    border-radius: 5px;
    margin-bottom: 2em;
  }

  .nidurstodur p {
    padding: 0.5em 1em;
    width: 100%;
  }

  .textaDiv {
    width: 100%;

    p {
      width: 100%;
      padding-left: 1em;
    }
  }

  > div > div > p {
    padding: 0 1em;
  }

  button {
    margin-bottom: 2em;
  }

  .annadMalDiv {
    display: flex;
    align-items: baseline;
    margin-top: 1em;

    input {
      margin-left: 1em;
    }
  }

  .react-date-picker {
    width: 50%;
    margin-bottom: 1em;

    .react-date-picker__wrapper {
      padding: 0.5em 1em;
      border-radius: 5px;
      border: none;
      background-color: $input-color;
    }

    input {
      padding: 0;
    }

    button {
      margin: 0;
    }
  }
.react-calendar__tile:disabled {
  background-color: #3f3f3f;
  color: #727272;
}
}