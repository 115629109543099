@import '../../variables.scss';

.initialButton {
  width: 80%;
  height: 4em;
}

.closeFyrriSeinni {
  position: absolute;
  top: 0;
  right: 0;
  width: 2em;
  padding: 0.3em;
}
.fyrriSeinni {
  form {
    margin-top: 1em;

    .dropDown {
      margin-bottom: 1rem;
    }

    select {
      width: 100%;
    }

    button {
      margin-top: 2em;
    }

    .react-date-picker {
      width: 100%;
      .react-date-picker__wrapper {
        padding: 0.5em 1em;
        border-radius: 5px;
        border: none;
        background-color: $input-color;
      }

      input {
        padding: 0;
      }

      button {
        margin: 0;
      }
    }
  }
}
