.results {
  margin-bottom: 8em;
  h2 {
    margin-top: 3em;
    margin-bottom: 1em;
    text-align: center;
  }

  .message {
    margin: 0 auto;
    height: 5em;
  }
}
