@import './variables.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  display: flex;
  justify-content: center;
}

body {
  font-family: 'Raleway', sans-serif;
  line-height: 1.6;
  width: 100%;
}

.App {
  background-color: white;
}

main,
header {
  margin-left: 10px;
  margin-right: 10px;
}

.SubApp {
  padding: 1em;
  background-color: white;
  width: 80%;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app__header {
  display: flex;
  flex-direction: column;
}

.app__header img {
  width: 30%;
  margin: 1em auto 0 auto;
}

.app__header h1 {
  width: 100%;
  text-align: center;
  margin: 1em 0;
}

input[type='text'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='email'],
select {
  padding: 0.5em 1em;
  border-radius: 5px;
  border: none;
  background-color: $input-color;
}

.hide {
  display: none !important;
}

.visable {
  display: block;
}

.correct {
  color: $correct;
}

.incorrect {
  color: $incorrect;
}

.neutral {
  background-color: $input-color;
}

.msf {
  border: 2px solid $msf-border;
  background-color: $msf !important;
  color: white;
}

.sf {
  border: 2px solid $sf-border;
  background-color: $sf !important;
  color: white;
}

.mf {
  border: 2px solid $mf-border;
  background-color: $mf !important;
  color: white;
}

.gf {
  border: 2px solid $gf-border;
  background-color: $gf !important;
  color: white;
}

.fullscreenIcon {
  width: 8vh;
  margin: 1em;
  position: fixed;
  transition: all 0.3s ease-in;

  right: 0;
  bottom: 0;
}

.fullscreenIcon:hover {
  width: 10vh;
  margin: 0.7em;
}

// Stílar fyrir tabs sem er hægt að fletta á milli
.mytabs {
  display: flex;
  flex-wrap: wrap;

  > label {
    padding: 1em 0;
    background: $input-color;
    width: 8em;
    text-align: center;
  }

  .tab {
    width: 100%;
    padding: 0 2em 2em 2em;
    background: #fff;
    order: 1;
    display: none;

    h2 {
      font-size: 3em;
    }
  }

  input[type='radio'] {
    display: none;
  }

  input[type='radio']:checked + label + .tab {
    display: block;
  }

  input[type='radio']:checked + label {
    background: rgb(137, 133, 255);
    color: white;
  }
}

