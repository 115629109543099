@import '../../variables.scss';

.headerDropDownClosed {
  top: -14rem;
}

.headerDropDown {
  top: 6rem;
}

.headerDropDownClosed,
.headerDropDown {
  position: absolute;
  z-index: 1;
  background-color: $mmsLjosBlar;
  padding: 2em 3em 1em 3em;
  right: 0;
  border-radius: 0 0 0 20px;
  transition: top 0.6s ease-in-out;

  p {
    padding: 0.5em 0;
  }

  p:hover {
    cursor: pointer;
  }

  .header__breyta {
    display: flex;
    flex-direction: column;

    label {
      margin-top: 1em;
    }

    button {
      margin-top: 1em;
    }

    button:hover {
      background-color: $mmsDokkBlar;
    }
  }

  .header__henda {
    div {
      margin: 1em 0;

      input {
        margin-right: 0.5em;
      }
    }

    .henda__button {
      width: 100%;

      display: flex;
      justify-content: center;

      button {
        width: 60%;
      }
    }
  }
}

.headerDiv {
  position: relative;
  z-index: 10;
  padding: 2em 0;
  width: 100%;
  background-color: $mmsDokkBlar;
  border-radius: 0 0 20px 20px;
  box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.1);

  .header {
    margin: 0;
    left: 0;
    display: flex;
    justify-content: space-between;

    .menntaLogo {
      margin-left: 2em;
      width: 3em;
      height: 3em;
      cursor: pointer;
    }

    .avatar,
    .avatarPlaceholder {
      margin-right: 2em;
      width: 3em;
      height: 3em;
    }

    .avatar {
      cursor: pointer;
    }
  }
}
