@import '../../variables.scss';

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  border-radius: 20px;
  margin: 3em;
  border: 2px solid black;
  z-index: 200;
  overflow: scroll;
  overflow-x: hidden;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1em 1em 0 0;
    height: 1.5em;
    color: black;
  }

  h2 {
    text-align: center;
  }

  table {
    margin: 0 auto;

    td {
      text-align: center;
      border-radius: $border-radius;
    }

    th {
      border: 1px solid black;
      border-radius: $border-radius;
      font-weight: bold;
      padding: 0.5em 1em;
    }
  }

  .skill {
    text-align: center;
    margin: 0.5em auto;
    width: 40%;
    border-radius: $border-radius;
  }

  .center {
    text-align: center;
  }

  .tab p {
    margin-bottom: 0.5em;
  }
}
