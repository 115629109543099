.skra {
  margin-top: 3em;

  h2 {
    text-align: center;
    margin-bottom: 1em;
  }

  .heildarSub {
    margin-top: 2em;
    margin-bottom: 4em;
    display: flex;
    justify-content: center;
  }

}
