$input-color: rgb(228, 226, 226);

$msf-border: rgb(241, 74, 74);
$msf: rgba(241, 74, 74, 0.5);
$sf-border: rgb(163, 159, 159);
$sf: rgba(163, 159, 159, 0.5);
$mf-border: rgb(63, 151, 63);
$mf: rgba(63, 151, 63, 0.5);
$gf-border: rgb(97, 97, 221);
$gf: rgba(97, 97, 221, 0.5);

$mmsDokkBlar: rgb(137, 133, 255);
$mmsLjosBlar: rgb(216, 193, 255);

$correct: green;
$incorrect: red;

$border-radius: 5px;
