.info {
  margin-bottom: 6em;
  h2 {
    margin-top: 3em;
    margin-bottom: 1em;
    text-align: center;
  }

  p {
    margin-bottom: 1em;
    text-align: justify;
  }

  .videoDiv {
    display: flex;
    justify-content: center;
    margin-top: 2em;
  }

  .downloadDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2em;

    a {
      width: 30%;
      margin-top: 2.5em;

      button {
        width: 100%;
      }
    }
  }

  .buttonDiv {
    margin-top: 3em;
    width: 100%;
    display: flex;
    justify-content: center;

    .tilBaka {
      cursor: pointer;
    }
  }
}
