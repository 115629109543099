.fyrraSeinnaDiv {
  display: flex;
  width: 77.77777%;
  margin: 0 auto;

  .fyrraDiv,
  .seinnaDiv {
    position: relative;
    margin-top: 1em;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
