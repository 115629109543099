.resultsEinstaklings {
  margin-left: 8.333333333%;
  padding-left: 20px;
  display: flex;
  flex-flow: column;

  > div {
    display: flex;
  }

  .content {
    display: flex;
    flex-flow: column;

    .textaDiv {
      width: 100%;
    }

    .textaDiv p {
      width: 100%;
      padding-left: 1em;
      border-radius: 5px;
    }

    .downloadNem {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2em;

      .downloadNem__pdf {
        width: 3em;
        margin-right: 1em;
        cursor: pointer;
      }

      button {
        padding: 1em;
      }
    }

    .umsogn {
      width: 77.77777%;
      margin-left: 11.11111%;
      margin-top: 1em;
    }

    table {
      background-color: white;
      width: 77.77777%;
      margin-left: 11.11111%;
      margin-top: 1em;

      th {
        flex: 1;
      }

      tr {
        display: flex;
        margin: 0;
        padding: 0;
        transition: all 0.3s ease-in;
      }

      td {
        flex: 1;
        margin: 0.1em;
        border-radius: 5px;
        text-align: center;
      }

      th:nth-child(2) {
        padding-left: 1em;
      }

      td:first-child {
        background-color: rgb(230, 227, 227);
        padding-left: 0.5em;
        margin-right: 1em;
        text-align: left;
      }

      tbody tr:hover {
        background-color: rgb(167, 167, 167);
        border-radius: 5px;
        padding: 0.1em 0;
        margin: 0.5em 0;
      }
    }

    .chartdiv {
      margin-top: 1em;
    }

    .chartdiv:last-child {
      margin-bottom: 1em;
    }
  }
}

@media (max-width: 799px) {
  .resultsEinstaklings {
    margin-left: calc(8.333333333% / 4);
  }
}
