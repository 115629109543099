.resultsMedal > div {
  display: flex;
}

.resultsMedal .textaDiv {
  width: 100%;
}

.resultsMedal .textaDiv p {
  width: 100%;
  padding-left: 1em;
  border-radius: 5px;
}

.chartdiv {
  display: flex;
  align-items: center;
}

.chartdiv img {
  margin-right: 1em;
  height: 2em;
}

.hoverbox {
  background-color: white;
  width: calc(100% - 3em);
}

.hoverbox,
.hoverbox * {
  box-sizing: border-box;
  position: relative;
}

.hoverbox__bottom {
  display: block;
}
.hoverbox__top {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 15px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

.hoverOver {
  visibility: visible;
  opacity: 1;
}

.hoverbox__top p:not(:last-child) {
  margin-bottom: 1em;
  width: auto;
}
