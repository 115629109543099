.kennari {
  h2 {
    margin-bottom: 1rem;
    text-align: center;
  }

  form {
    display: flex;
    flex-flow: column;

    label {
      margin-top: 1em;
    }

    button {
      width: 50%;
      margin: 2em auto 0 auto;
    }

    div {
      display: flex;
      justify-content: space-between;
    }

    p {
      width: auto;
      margin: 0;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
    }
  }
}
