.resultsTafla {
  > div {
    display: flex;
  }

  .textaDiv {
    width: 100%;

    p {
      width: 100%;
      padding-left: 1em;
      border-radius: 5px;
    }
  }

  .resultTaflaDiv {
    overflow-x: scroll;

    table {
      position: relative;
      width: 100%;
      margin-top: 4em;
      margin-bottom: 3em;
      background-color: white;

      th {
        width: 6em;
        font-size: 12px;
        text-align: left;
        padding-left: 1em;
        align-content: center;
        margin-right: 2em;
      }

      th:first-child {
        flex: 3;
        margin-right: 16em;
      }

      th:not(:first-child) {
        flex: 1;
        transform: rotate(-90deg);
        margin-bottom: 2em;
      }

      tr {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        transition: all 0.3s ease-in;
      }

      td {
        flex: 1;
        margin: 0.1em;
        border-radius: 5px;
        text-align: center;
      }

      td:first-child {
        background-color: rgb(230, 227, 227);
        padding-left: 0.5em;
        margin-right: 2em;
      }

      td:first-child,
      th:first-child {
        flex-grow: 3;
        text-align: left;
      }

      tbody tr:hover {
        background-color: rgb(167, 167, 167);
        border-radius: 5px;
        padding: 0.1em 0;
        margin: 0.5em 0;
      }
    }
  }

  .downloadDiv {
    display: flex;
    flex-direction: column;

    p {
      margin-top: 2em;
      text-align: center;
    }
    > div {
      display: flex;
      justify-content: space-around;
      margin: 2em 0;

      div {
        display: flex;
        justify-content: center;

        img {
          margin-right: 1em;
        }

        .excelIcon {
          width: 2em;
        }

        .pdfIcon {
          width: 3em;
        }
      }
    }
  }
}
