.auth {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 3em;

  h2 {
    text-align: center;
  }
}

.login {
  width: 100%;
  display: flex;
  flex-flow: column;

  form {
    display: flex;
    flex-flow: column;

    label {
      margin-top: 1em;
    }
  }

  .login__error {
    margin-top: 2em;
  }

  .login__buttons {
    display: flex;
    margin: 15px;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Adjust gap between buttons as needed */
  }

  .login__button {
    width: 50%;
  }

  .login__reset-button {
    width: 30%; /* Adjust width to be smaller */
    padding: 5px 10px; /* Adjust padding to make it smaller */
    font-size: 0.8em; /* Make the text smaller */
  }
}
